import { Button, Text, View, ViewProps } from '@adobe/react-spectrum';
import { ColorVersion } from '@react-types/shared';
import LinkOut from '@spectrum-icons/workflow/LinkOut';

const WebcomicAppAd = (props: ViewProps<ColorVersion>) => (
  <View
    backgroundColor="gray-50"
    borderColor="light"
    borderRadius="regular"
    borderWidth="thin"
    padding="size-200"
    {...props}
  >
    <View marginBottom="size-200">
      {'Need a website for your webcomic? Create one now on webcomic.app.'}
    </View>
    <Button
      elementType="a"
      href="https://webcomic.app"
      target="_blank"
      variant="cta"
      width="100%"
    >
      <LinkOut />
      <Text>
        {'Visit webcomic.app'}
      </Text>
    </Button>
  </View>
);

export default WebcomicAppAd;
