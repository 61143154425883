import { Divider, Flex, Footer as SpectrumFooter, Link as SpectrumLink, Text, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import { useBreakpoint } from '@react-spectrum/utils';
import Link from 'next/link';

interface Props {
  isOnHomePage?: boolean;
}

const Footer = ({ isOnHomePage = false }: Props) => {
  const { matchedBreakpoints } = useBreakpoint();
  const isInBaseBreakpointRange = !matchedBreakpoints.includes('S');

  return (
    <SpectrumFooter
      {...!isOnHomePage && {
        marginBottom: { base: 'size-200', S: 'size-400' },
        marginTop: { base: 'size-1000', S: 'size-1600' }
      }}
    >
      <Divider
        marginBottom="size-200"
        size="M"
      />
      <Flex
        alignItems={{ base: 'center', S: 'start' }}
        justifyContent={{ S: 'space-between' }}
        {...isInBaseBreakpointRange && { direction: 'column', rowGap: 'size-100' }}
      >
        <Flex
          {...isInBaseBreakpointRange && { UNSAFE_style: { textAlign: 'center' } }}
          alignItems={{ base: 'center', S: 'start' }}
          direction="column"
        >
          <div>
            {'Contact: '}
            <Link href="mailto:contact@comics-jobs.com">
              <SpectrumLink
                isQuiet
                variant="secondary"
              >
                {'contact@comics-jobs.com'}
              </SpectrumLink>
            </Link>
          </div>
          <Link href="/privacy">
            <SpectrumLink
              isQuiet
              variant="secondary"
            >
              {'Privacy'}
            </SpectrumLink>
          </Link>
          <Link href="/terms-of-use">
            <SpectrumLink
              isQuiet
              variant="secondary"
            >
              {'Terms of Use'}
            </SpectrumLink>
          </Link>
          <Text>
            {'Copyright © 2025 Chafic Najjar'}
          </Text>
        </Flex>
        <Flex>
          <TooltipTrigger delay={0}>
            <SpectrumLink UNSAFE_className="link">
              <a
                aria-label="Bluesky"
                href="https://bsky.app/profile/comics-jobs.com"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  role="img"
                  style={{
                    display: 'block',
                    padding: 'var(--spectrum-global-dimension-size-125)'
                  }}
                  viewBox="0 0 24 24"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 10.8c-1.087-2.114-4.046-6.053-6.798-7.995C2.566.944 1.561 1.266.902 1.565.139 1.908 0 3.08 0 3.768c0 .69.378 5.65.624 6.479.815 2.736 3.713 3.66 6.383 3.364.136-.02.275-.039.415-.056-.138.022-.276.04-.415.056-3.912.58-7.387 2.005-2.83 7.078 5.013 5.19 6.87-1.113 7.823-4.308.953 3.195 2.05 9.271 7.733 4.308 4.267-4.308 1.172-6.498-2.74-7.078a8.741 8.741 0 0 1-.415-.056c.14.017.279.036.415.056 2.67.297 5.568-.628 6.383-3.364.246-.828.624-5.79.624-6.478 0-.69-.139-1.861-.902-2.206-.659-.298-1.664-.62-4.3 1.24C16.046 4.748 13.087 8.687 12 10.8Z"
                    fill="var(--spectrum-global-color-gray-900)"
                  />
                </svg>
              </a>
            </SpectrumLink>
            <Tooltip>
              {'Bluesky'}
            </Tooltip>
          </TooltipTrigger>
          <TooltipTrigger delay={0}>
            <SpectrumLink UNSAFE_className="link">
              <a
                aria-label="Threads"
                href="https://www.threads.net/@comics_jobs"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  role="img"
                  style={{
                    display: 'block',
                    padding: 'var(--spectrum-global-dimension-size-125)'
                  }}
                  viewBox="0 0 24 24"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.186 24h-.007c-3.581-.024-6.334-1.205-8.184-3.509C2.35 18.44 1.5 15.586 1.472 12.01v-.017c.03-3.579.879-6.43 2.525-8.482C5.845 1.205 8.6.024 12.18 0h.014c2.746.02 5.043.725 6.826 2.098 1.677 1.29 2.858 3.13 3.509 5.467l-2.04.569c-1.104-3.96-3.898-5.984-8.304-6.015-2.91.022-5.11.936-6.54 2.717C4.307 6.504 3.616 8.914 3.589 12c.027 3.086.718 5.496 2.057 7.164 1.43 1.783 3.631 2.698 6.54 2.717 2.623-.02 4.358-.631 5.8-2.045 1.647-1.613 1.618-3.593 1.09-4.798-.31-.71-.873-1.3-1.634-1.75-.192 1.352-.622 2.446-1.284 3.272-.886 1.102-2.14 1.704-3.73 1.79-1.202.065-2.361-.218-3.259-.801-1.063-.689-1.685-1.74-1.752-2.964-.065-1.19.408-2.285 1.33-3.082.88-.76 2.119-1.207 3.583-1.291a13.853 13.853 0 0 1 3.02.142c-.126-.742-.375-1.332-.75-1.757-.513-.586-1.308-.883-2.359-.89h-.029c-.844 0-1.992.232-2.721 1.32L7.734 7.847c.98-1.454 2.568-2.256 4.478-2.256h.044c3.194.02 5.097 1.975 5.287 5.388.108.046.216.094.321.142 1.49.7 2.58 1.761 3.154 3.07.797 1.82.871 4.79-1.548 7.158-1.85 1.81-4.094 2.628-7.277 2.65Zm1.003-11.69c-.242 0-.487.007-.739.021-1.836.103-2.98.946-2.916 2.143.067 1.256 1.452 1.839 2.784 1.767 1.224-.065 2.818-.543 3.086-3.71a10.5 10.5 0 0 0-2.215-.221z"
                    fill="var(--spectrum-global-color-gray-900)"
                  />
                </svg>
              </a>
            </SpectrumLink>
            <Tooltip>
              {'Threads'}
            </Tooltip>
          </TooltipTrigger>
          <TooltipTrigger delay={0}>
            <SpectrumLink UNSAFE_className="link">
              <a
                aria-label="Twitter"
                href="https://twitter.com/comics_jobs"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  role="img"
                  style={{
                    display: 'block',
                    padding: 'var(--spectrum-global-dimension-size-125)'
                  }}
                  viewBox="0 0 24 24"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
                    fill="var(--spectrum-global-color-gray-900)"
                  />
                </svg>
              </a>
            </SpectrumLink>
            <Tooltip>
              {'Twitter'}
            </Tooltip>
          </TooltipTrigger>
          <TooltipTrigger delay={0}>
            <SpectrumLink UNSAFE_className="link">
              <a
                aria-label="Facebook"
                href="https://www.facebook.com/ComicsJobs"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  role="img"
                  style={{
                    display: 'block',
                    padding: 'var(--spectrum-global-dimension-size-125)'
                  }}
                  viewBox="0 0 24 24"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978.401 0 .955.042 1.468.103a8.68 8.68 0 0 1 1.141.195v3.325a8.623 8.623 0 0 0-.653-.036 26.805 26.805 0 0 0-.733-.009c-.707 0-1.259.096-1.675.309a1.686 1.686 0 0 0-.679.622c-.258.42-.374.995-.374 1.752v1.297h3.919l-.386 2.103-.287 1.564h-3.246v8.245C19.396 23.238 24 18.179 24 12.044c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.628 3.874 10.35 9.101 11.647Z"
                    fill="var(--spectrum-global-color-gray-900)"
                  />
                </svg>
              </a>
            </SpectrumLink>
            <Tooltip>
              {'Facebook'}
            </Tooltip>
          </TooltipTrigger>
          <TooltipTrigger delay={0}>
            <SpectrumLink UNSAFE_className="link">
              <a
                aria-label="Product Hunt"
                href="https://www.producthunt.com/posts/comics-jobs"
                rel="noreferrer"
                target="_blank"
              >
                <svg
                  role="img"
                  style={{
                    display: 'block',
                    padding: 'var(--spectrum-global-dimension-size-125)'
                  }}
                  viewBox="0 0 24 24"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.604 8.4h-3.405V12h3.405c.995 0 1.801-.806 1.801-1.801 0-.993-.805-1.799-1.801-1.799zM12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zm1.604 14.4h-3.405V18H7.801V6h5.804c2.319 0 4.2 1.88 4.2 4.199 0 2.321-1.881 4.201-4.201 4.201z"
                    fill="var(--spectrum-global-color-gray-900)"
                  />
                </svg>
              </a>
            </SpectrumLink>
            <Tooltip>
              {'Product Hunt'}
            </Tooltip>
          </TooltipTrigger>
        </Flex>
      </Flex>
    </SpectrumFooter>
  );
};

export default Footer;
